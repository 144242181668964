<template>
  <DefaultPage :title="$t('app.user.onboarding.places.title')">
    <template #left>
      <BackButton path="/user/home" />
    </template>
    <div>
      <div class="p-3">{{ $t("app.user.onboarding.places.instruction") }}</div>
      <DefaultList url="/api/places">
        <template #item="{ item }">
          <div class="p-3">
            <div class="list-item-title">{{ item.name }}</div>
            <a
              href="#"
              @click.prevent="itemClicked(item)"
              class="stretched-link"
            ></a>
          </div>
        </template>
      </DefaultList>
    </div>
  </DefaultPage>
</template>

<script>
import httpService from "@/services/HttpService";
import BackButton from "@/components/BackButton.vue";

export default {
  components: {
    BackButton,
  },
  methods: {
    itemClicked(item) {
      httpService.put(`/api/user/places/${item.id}`).then(() => {
        this.$router.push({
          path: `/user/onboarding/places/${item.id}/recycling-streets`,
        });
      });
    },
  },
};
</script>
